.autohide {
  overflow: hidden;
}
.autohide:hover {
  overflow: auto;
}
.errorMsg {
  color: red;
  padding: 0.5rem;
}

.app-warning{
  color: orangered;
  padding: 0.5rem;
}

.detail-view .detail {
  flex-grow: 1;
  margin: 0.5rem;
}

.icon {
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:active {
    background-color: white;
  }
}

.card-overlay {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.text-red-disconnected {
  color: #e8221e;
}

.text-green-connected {
  color: #31944a;
}

.margin-b-60 {
  margin-bottom: 15px !important;
}

.gradient {
  @apply bg-gradient-to-r from-yardBackgroundDark to-yardBackgroundLight overflow-auto;
}

.centralize {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fillPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

td {
  font-size: 1rem;
}

.react-datetimerange-picker__wrapper {
  border-radius: 0.5rem !important;
}

.MuiInputLabel-root {
  font-size: 13px !important;
  background-color: white;
}

.tooltip {
  font-size: "3rem" !important;
}

.addMaterDataButton {
  border: 1px solid var(--border-color);
  color: var(--border-color);
  padding: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.3rem;
  &:hover {
    border: 1px solid var(--border-hover-color);
    color: var(--border-hover-color);
    cursor: pointer;
  }
}

.popover {
  position: absolute;
  background-color: white;
  padding: 2rem 4rem;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiTooltip-tooltip{
  font-size: 1rem !important;
}

.react-datetimerange-picker__calendar-button{
  display: none;
}
.MuiBox-root{
  border:none !important;
}
.label{
  font-size: 1.5rem !important;
  margin: 1rem 0rem;
  display: block;
  font-weight: bold !important;
}