.section {
  width: 400px;
  height: 320px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.heidelbergLogo {
  height: 14px;
}

.loginCaption {
  font-size: 13px;
  color: #aaaaaa;
  font-weight: 200;
}

.signIn {
  font-size: 20px;
}

.loginLogo {
  text-align: center;
  align-self: center;

}

.card {
  padding: 1rem 1rem 2rem 1rem;
  overflow-y: auto;
  background-color: #ffffff;

  &__left {
    @apply p-4 h-full flex flex-grow min-w-0 flex-col;
  }

  &__right {
    transition: all ease-in-out .2s;
    // opacity: 1;
    @apply border-l border-gray-200 h-full flex-shrink-0 bg-white w-96 py-6 flex flex-col justify-start;
  }

}


.trackOrderTitle {
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
  line-height: 34px;
  font-family: "inter";
}

.loginTitle {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  margin: 20px;
  letter-spacing: 0px;
  color: #00274D;
}

.loginSubTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #54708C;
}

.trackOrderDetails {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  opacity: 50%;
}

.trackOrderContainer {
  text-align: center;
  margin: auto;

}

.trackOrderContainer img {
  justify-content: center;
  display: inline-block;
  justify-self: center;
  height: 250px;
  margin: auto;
  align-self: center;
}


.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.loginLogo {
  width: 280px;
}

.radiusCard {
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.whiteButton {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}