.mainNavigation{
    // First Level Li
    
    display: flex;
    li,a{
        background-color: white;
        z-index: 1000;
        position: relative;
    }

    &>li>a.active{
        @apply border-b-2;
    }

    &>li{
        padding: .5rem;
        position: relative;
    }

    /* This hides the dropdowns */
    li ul {
        visibility: collapse;
        opacity: 0;
        background-color: white;
        color: black;
        @apply shadow-md min-w-full ;
        transition: all .3s;
        transform: translateY(-50%);
        position: absolute;
        z-index: -1;
    }
    
    ul li a:hover {
        @apply bg-slate-200;
    }
    
    /* Display the dropdown */
    li>ul{
        border-radius: .3rem;
        overflow: hidden;
    }

    li:hover>ul {
        visibility: visible;
        opacity: 1;
        transform: translateY(.7rem);
    }
    
    /* Remove float from dropdown lists */
    li:hover li {
        float: none;
    }

    li:hover>a {
        // background: #1bc2a2;
        @apply border-yardBlue;
    }
    
    .active{
        @apply border-yardBlue text-yardBlue font-bold;
    }
    
    // &>li:hover>a,a.active {
    //     // background: #1bc2a2;
    //     @apply border-b-2;
    // }
    
    li:hover li a:hover {
        // background: #2c3e50;
       
    }
    
    li ul li {
        border-top: 0;
    }

    
}

.ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    li {
        display: block;
        position: relative;
        float: left;
    }
    
    li a {
        display: block;
        padding: .5rem;
        text-decoration: none;
        @apply text-yardDeepBlue; 
        white-space: nowrap;

    }
    
     /* Displays second level dropdowns to the right of the first level dropdown */
     ul ul {
        left: 100%;
        top: 0;
    }
}

.notReady{
    position:fixed;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
}


