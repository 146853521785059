.card{
    padding: 1rem;
    overflow-y: auto;
    &__left{
        &__grid{
            height: calc(100% - 90px);
            min-height: 0px;
            flex-shrink: 1;
        }
        @apply p-4 h-full flex flex-grow min-w-0 flex-col;
    }
    &__right{
        transition: all ease-in-out .2s;
        // opacity: 1;
        @apply border-l border-gray-200 h-full flex-shrink-0 bg-white w-96 py-6 flex flex-col justify-start;
    }
}

.hide{
    display: none;
    width:0;
    overflow: hidden !important;
    padding: 0px !important;
}