.detail-view{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .detail{
        flex-basis: 250px;
    }
}


