.modal{
    overflow: hidden;
    /* height: 100%; */
    max-height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    &__header{
        .left{
          font-weight: bold;  
          font-size: 1.7rem;
        }
    }
    &__body{
        padding: 2rem ;
        overflow: auto;
    }
}