html {
  font-size: 11px;
}

body {
  min-height: calc(99vh);
  height: calc(99vh);
  min-width: 100vh;
  @apply bg-gradient-to-r from-yardBackgroundDark to-yardBackgroundLight overflow-auto;
  overflow: hidden;
}

#mainContent {
  min-height: 500px;
}

.MuiStepLabel-alternativeLabel{
  font-size: 20px
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.hm-paging-dot {
  width: 20px;
  border: solid 1px #000;
  margin-top: 20px;
}

.rs-anim-fade.rs-anim-in.rs-picker-popup-daterange.rs-picker-popup.placement-bottom-start {
  right: 15px !important;
  left: auto !important;
}
.rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
  //color: blue;
}
